import gql from 'graphql-tag';

export class ServicoQueries {
    public static getUltimosServicos = gql`query{
        ultimosServicos{
            id
            distanciaExcedida
            valorTotalDistanciaExcedida
            valorTotalServico
            agencia{
                id
                nome
                codigo
                endereco
                numero
                bairro
                cnpj
                cep
                cidade{
                    nome
                    codigoIbge
                    estado{
                        sigla
                    }
                }
            }
            fiscalizacoes{
                id
                distanciaPercorrida
                financiamento{
                    mutuario
                    valor
                }
            }
        }
    }`;

    public static getServicosPorLote = gql`query($loteId: Int!, $page: Int, $offset: Int){
        servicosPorLote(lote_id: $loteId, page: $page, offset: $offset){
            totalCount
            itens{
                id
                distanciaExcedida
                valorTotalDistanciaExcedida
                valorTotalServico
                agencia{
                    id
                    nome
                    codigo
                    endereco
                    numero
                    bairro
                    cnpj
                    cep
                    cidade{
                        nome
                        codigoIbge
                        estado{
                            sigla
                        }
                    }
                }
                fiscalizacoes{
                    id
                    distanciaPercorrida
                    financiamento{
                        mutuario
                        valor
                    }
                }
                notaFiscal{
                    id
                    dataEmissao
                    numero
                    valorServicos
                }
            }
        }
    }`;

    public static getLotesServico = gql`query {
        lotesServico{
            id
            codigo
            finalizadoEm
            totalServicos
        }
    }`

    public static finalizarLoteServico = gql`mutation($id:Int!){
        lote: finalizarLoteServico(id: $id){
            id
            codigo
            finalizadoEm
        }
    }`
}