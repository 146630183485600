<template>
    <page title="Serviços">
        <v-breadcrumbs slot="breadcrumb" class="pa-0">
            <v-breadcrumbs-item exact :to="{name:'dashboard'}">Início</v-breadcrumbs-item>
            <v-breadcrumbs-divider>></v-breadcrumbs-divider>
            <v-breadcrumbs-item exact :to="{name:'todas_fiscalizacoes'}">Fiscalizações</v-breadcrumbs-item>
        </v-breadcrumbs>
        <div slot="menu" class="d-flex justify-space-between align-center" v-if="lote">
            <v-item-group style="height: max-content; border: solid thin rgba(0, 0, 0, 0.12); border-radius: 6px" >
                <v-btn tile depressed color="white"
                       @click="openChangeLoteDialog"
                       style="border-top-left-radius: inherit;border-bottom-left-radius: inherit;">
                    <span>Lote {{lote.codigo}}</span>
                    <v-icon right>mdi-chevron-down</v-icon>
                </v-btn>
                <v-menu bottom offset-y open-on-hover v-if="lote.finalizadoEm !== null">
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn tile depressed
                           v-bind="attrs" v-on="on">
                      <v-icon left>mdi-cog-outline</v-icon>
                        Ações
                      <v-icon right>mdi-menu-down</v-icon>
                    </v-btn>
                  </template>
                  <v-list>
                    <v-list-item @click="openCobrarServicosDialog" >
                      <v-list-item-title>Emitir Notas e enviar para o banco</v-list-item-title>
                    </v-list-item>
                    <v-list-item @click="openRemoverFiscalizacoesDialog" >
                      <v-list-item-title>Remover Fiscalizações</v-list-item-title>
                    </v-list-item>
                  </v-list>
                </v-menu>
                <v-menu bottom offset-y open-on-hover v-if="lote.finalizadoEm !== null">
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn tile depressed
                             :disabled="downloadingResumo || downloadingSolicitacao"
                             v-bind="attrs" v-on="on">
                        <v-icon left>mdi-file-download-outline</v-icon>
                        Baixar
                        <v-icon right>mdi-menu-down</v-icon>
                      </v-btn>
                    </template>
                    <v-list>
                      <v-list-item @click="downloadResumo">
                        <v-list-item-title>Resumo</v-list-item-title>
                      </v-list-item>
                      <v-list-item @click="downloadSolicitacaoServico">
                        <v-list-item-title>Solicitações de Pagamento</v-list-item-title>
                      </v-list-item>
                    </v-list>
                  </v-menu>

                <v-btn v-if="!lote.finalizadoEm" tile depressed @click="finalizarLote" color="blue accent-4 white--text"
                       style="border-top-right-radius: inherit;border-bottom-right-radius: inherit;">
                    <v-icon left>mdi-file-table</v-icon> Finalizar lote
                </v-btn>
            </v-item-group>
            <div v-if="lote.finalizadoEm" style="opacity: 0.6" class="d-inline-flex align-center">
                <div class="d-flex align-center">

                    <div>
                        <p class="mb-0 grey--text " style="font-size:10px; line-height: 12px">Finalizado em</p>
                        <p class="mb-0 caption grey--text text--darken-2" style="line-height: 12px">{{new Date(lote.finalizadoEm).toLocaleDateString()}}</p>
                    </div>
                </div>
            </div>
        </div>

        <div class="mt-2" v-if="totalCount > 0">
            <span class="subtitle-2 font-weight-regular">{{totalCount}} resultados encontrados</span>
        </div>

        <div class="text-center" v-if="servicos">
            <v-pagination v-model="page" total-visible="8"
                          :length="Math.ceil(totalCount / offset)"
                          @input="pageChanged"></v-pagination>
        </div>

        <v-card class="mt-3">
            <v-list three-line v-if="servicos && servicos.length > 0">
                <template v-for="(servico, index) in servicos">
                    <v-list-item @click="viewServico(servico, index)" >
                        <v-list-item-content>
                            <v-list-item-title>{{totalFiscalizacoes(servico.fiscalizacoes)}} para Agencia {{servico.agencia.codigo}} de {{servico.agencia.cidade.nome}}/{{servico.agencia.cidade.estado.sigla}}</v-list-item-title>
                            <template v-if="servico.notaFiscal === null">
                                <v-list-item-subtitle>Valor do serviço: {{servico.valorTotalServico | formatNumber}}</v-list-item-subtitle>
                                <v-list-item-subtitle>Distância excedida: {{servico.distanciaExcedida}} Km ({{servico.valorTotalDistanciaExcedida | formatNumber}})</v-list-item-subtitle>
                            </template>
                            <template v-else>
                                <v-list-item-subtitle>Valor da Nota: {{servico.notaFiscal.valorServicos | formatNumber}}</v-list-item-subtitle>
                                <v-list-item-subtitle>Nº Nota: {{servico.notaFiscal.numero}}</v-list-item-subtitle>
                            </template>
                        </v-list-item-content>
                        <v-list-item-action >
                            <v-chip v-if="servico.notaFiscal === null && servico.agencia.cidade.codigoIbge === null"
                                    x-small label color="red" text-color="white"
                                    class="font-weight-bold px-2">SEM COD. IBGE</v-chip>
                            <v-chip v-if="lote.finalizadoEm && servico.notaFiscal === null"
                                    x-small label color="orange" text-color="white"
                                    class="font-weight-bold px-2">NOTA NÃO EMITIDA</v-chip>
                        </v-list-item-action>

                    </v-list-item>

                    <v-divider ></v-divider>
                </template>
            </v-list>
            <div v-else-if="loading === false && (servicos === null || servicos.length === 0)" class="text-center pa-6">
                <h1 class="display-3 mb-4">Ops!</h1>
                <span class="headline font-weight-light">Nenhum resultado encontrado!</span>
            </div>
            <v-overlay :value="loading" color="white" absolute opacity="0.8" z-index="1" />
            <v-progress-linear :active="loading" :indeterminate="loading" absolute top color="secondary" />
        </v-card>

        <div class="text-center mt-3" v-if="servicos">
            <v-pagination v-model="page" total-visible="8"
                          :length="Math.ceil(totalCount / offset)"
                          @input="pageChanged"></v-pagination>
        </div>

        <lotes-dialog ref="lotesDialog" @selected="changeLote" />
        <servico-dialog ref="servicoDialog" @success="onNfseSuccess" />
<!--        <enviar-batch-nota-fiscal-dialog ref="enviarBatchNotaFiscalDialog" @completed="getServicosPorLote(lote.id)" @canceled="getServicosPorLote(lote.id)" />-->
        <remover-fiscalizacoes-dialog ref="removerFiscalizacoesDialog" @completed="getData" />
        <cobrar-servicos-dialog ref="cobrarServicosDialog" />
    </page>
</template>

<script>
    // @ts-ignore
    import page from "../../../../components/Page";
    import {ServicoQueries} from "../../../../queries/ServicoQueries";
    // @ts-ignore
    import LotesDialog from "./LotesDialog";
    // @ts-ignore
    import ServicoDialog from "./ServicoDialog";
    // @ts-ignore
    import RemoverFiscalizacoesDialog from "./RemoverFiscalizacoesDialog";
    // @ts-ignore
    import CobrarServicosDialog from "@/views/financeiro/CobrarServicosDialog";

    export default {
        name: "Index",
        components: {
          RemoverFiscalizacoesDialog,
            CobrarServicosDialog,
            LotesDialog,
            page,
            ServicoDialog,
        },
        watch:{
            lote(lote){
                this.$router.push({query:{lote: lote.id, page: '1'}});
            },
            $route(to, from) {
                if(from.query.lote === undefined){
                    this.getServicosPorLote(to.query.lote);
                    return;
                }

                if(to.query.page === undefined){
                    return;
                }

                this.page = parseInt(to.query.page);
                if(to.query.page === from.query.page){
                    this.getServicosPorLote(to.query.lote);
                }else{
                    this.getServicosPorLote(to.query.lote);
                }
            }
        },
        data(){
            return{
                offset: 10,
                page: 1,
                totalCount: 0,
                loading: true,
                lotes: [],
                lote: null,
                servicos: [],
                downloadingResumo: false,
                downloadingSolicitacao: false,
            }
        },

        methods: {
            getData(){
              this.loading = true;
              this.getLotes().then(lotes => {
                if(this.$route.query.lote && this.$route.query.page){
                  this.lote = lotes.find(lote => lote.id === parseInt(this.$route.query.lote));
                  this.page = parseInt(this.$route.query.page);
                  this.getServicosPorLote(this.lote.id)
                }else{
                  this.lote = this.lotes[0]
                }
              });
            },
            async getLotes(){
                return this.$apollo.query({
                    query: ServicoQueries.getLotesServico,
                }).then((result) => {
                    this.lotes = result.data.lotesServico.map(lote => {
                        lote.codigo = this._.padStart(lote.codigo, 8, '0');
                        return lote;
                    });

                    return this.lotes;
                });
            },
            async getServicosPorLote(loteId){
                this.loading = true;
                return this.$apollo.query({
                    query: ServicoQueries.getServicosPorLote,
                    variables: {
                        loteId: loteId,
                        page: this.page - 1,
                        offset: this.offset
                    }
                }).then((result) => {
                    this.servicos = result.data.servicosPorLote.itens;
                    this.totalCount = result.data.servicosPorLote.totalCount;
                    this.loading = false;
                });
            },
            pageChanged(value){
                this.$router.push({query:{lote: this.lote.id, page:this.page}});
            },
            totalFiscalizacoes(fiscalizacoes) {
                const total = fiscalizacoes.length;
                let label = ' Fiscalização';
                if(total > 1){
                    label = ' Fiscalizações';
                }
                return total + label;
            },
            openChangeLoteDialog(){
                this.$refs.lotesDialog.openDialog(this.lotes, this.lote.id);
            },
            changeLote(lote){
                this.lote = lote;
            },
            viewServico(servico, index){
                this.$refs.servicoDialog.openDialog(servico, index);
            },
            onNfseSuccess(notaFiscal, index){
                this.servicos[index].notaFiscal = notaFiscal;
            },
            finalizarLote(){
                this.$apollo.mutate({
                    mutation: ServicoQueries.finalizarLoteServico,
                    variables: {
                        id: this.lote.id
                    }
                }).then((result) => {
                    this.lote = result.data.lote;
                    this.lote.codigo = this._.padStart(this.lote.codigo, 8, '0');
                    //this.loading = false;
                });
            },
            downloadResumo() {
                if(this.servicos.find(servico => servico.notaFiscal === null)){
                    this.$dialog.warning({
                        text: 'O resumo não pode ser gerado pois ainda existem serviços sem notas fiscais emitidas',
                        title: 'Atenção',
                        actions: {
                            text: "ok"
                        }
                    });
                    return;
                }

                this.downloadingResumo = true;
                this.$axios.get('/servicos/resumoLote/' + this.lote.id, {responseType: 'blob'}).then((response) => {
                    const fileLink = document.createElement('a');
                    fileLink.href = window.URL.createObjectURL(new Blob([response.data]));
                    const fileName = response.headers['content-disposition'].split('filename=')[1];
                    fileLink.setAttribute('download', fileName);
                    document.body.appendChild(fileLink);
                    fileLink.click();
                    document.body.removeChild(fileLink);
                    this.downloadingResumo = false;
                }).catch((error) => {
                    this.downloadingResumo = false;
                    this.$dialog.error({
                        text: 'Não foi possível baixar o arquivo. Contate o desenvolvedor.',
                        title: 'Ops',
                    });

                });
            },
            downloadSolicitacaoServico() {
                if(this.servicos.find(servico => servico.notaFiscal === null)){
                    this.$dialog.warning({
                        text: 'A solicitação dos serviços não pode ser gerada pois ainda existem serviços sem notas fiscais emitidas',
                        title: 'Atenção',
                        actions: {
                            text: "ok"
                        }
                    });
                    return;
                }

                this.downloadingSolicitacao = true;
                this.$axios.get('/servicos/solicitacaoServico/' + this.lote.id, {responseType: 'blob'}).then((response) => {
                    const fileLink = document.createElement('a');
                    fileLink.href = window.URL.createObjectURL(new Blob([response.data]));
                    const fileName = response.headers['content-disposition'].split('filename=')[1];
                    fileLink.setAttribute('download', fileName);
                    document.body.appendChild(fileLink);
                    fileLink.click();
                    document.body.removeChild(fileLink);
                    this.downloadingSolicitacao = false;
                }).catch((error) => {
                    this.downloadingSolicitacao = false;
                    this.$dialog.error({
                        text: 'Não foi possível baixar o arquivo. Contate o desenvolvedor.',
                        title: 'Ops',
                    });

                });
            },
            openCobrarServicosDialog(){
                this.$refs.cobrarServicosDialog.openDialog(null, this.lote.id)
            },
            openRemoverFiscalizacoesDialog(){
                this.$refs.removerFiscalizacoesDialog.openDialog(this.lote.id);
            }
        },
        mounted() {
          document.title = "Serviços - JF Assessoria Rural"
          this.getData();
        }
    }
</script>

<style scoped>

</style>