<template>
    <v-dialog v-model="dialog" scrollable persistent max-width="400px">
        <v-card v-if="dialog">
            <v-card-title>
                <span class="headline">Selecione um lote</span>
            </v-card-title>
            <v-card-text>
                <v-list class="py-0">
                    <v-list-item-group v-model="selected" mandatory active-class="orange white--text">
                        <template v-for="(lote, index) in lotes">
                            <v-list-item :key="lote.id" class="">
                                <template v-slot:default="{ active, toggle }">
                                    <v-list-item-content>
                                        <v-list-item-title>{{lote.codigo}}</v-list-item-title>
                                        <v-list-item-title class="caption">{{lote.totalServicos}} serviços</v-list-item-title>
                                    </v-list-item-content>
                                    <v-list-item-action >
                                      <template v-if="lote.finalizadoEm">
                                        <span class="caption ">Fechado em</span>
                                        <span class="caption">{{lote.finalizadoEm | moment('DD/MM/YYYY HH:mm')}}</span>
                                      </template>
                                      <v-chip v-else x-small color="red" label dark>Aberto</v-chip>
                                    </v-list-item-action>
                                </template>
                            </v-list-item>
                        </template>
                    </v-list-item-group>
                </v-list>
            </v-card-text>
            <v-card-actions>
                <div class="pa-3" style="width: 100%">
                    <v-btn text @click="closeDialog">Cancelar</v-btn>

                    <div class="float-right">
                        <v-btn text color="primary" @click="ok">
                            Ok
                        </v-btn>
                    </div>

                </div>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
    export default {
        name: "LotesDialog",
        data() {
            return {
                dialog: false,
                lotes: [],
                selected: null,
            }
        },
        methods: {
            openDialog(lotes, loteId) {
                this.dialog = true;
                this.lotes = lotes;
                this.selected = this.lotes.findIndex(lote => lote.id === loteId);
            },
            closeDialog() {
                this.dialog = false;
            },
            ok(){
                this.closeDialog();
                this.$emit('selected', Object.assign({}, this.lotes[this.selected]));
            }
        }
    }
</script>

<style scoped>

</style>