<template>
  <v-dialog v-model="dialog" persistent scrollable max-width="900px">
    <v-card v-if="dialog" min-height="400px">
      <v-card-title>
        <span class="headline">Remover Fiscalizações</span>
      </v-card-title>
      <v-card-text class="py-0 overflow-hidden">
          <template v-if="fiscalizacoes">
              <template v-if="fiscalizacoes.length > 0">
                  <span>{{fiscalizacoes.length}} {{fiscalizacoes.length > 1 ? 'servviços' : 'serviço'}} fiscalizações</span>
                  <v-row no-gutters class="mt-1">
                      <v-col>
                          <span class="subtitle-2">Inclusas</span>
                          <v-text-field placeholder="Busca" outlined dense class="mt-1"
                                        v-model="filter1" hide-details clearable />

                      </v-col>
                      <v-col class="shrink" style="min-width: 60px">
                      </v-col>
                      <v-col>
                          <span class="subtitle-2">Exclusas</span>
                          <v-text-field placeholder="Busca" outlined dense class="mt-1"
                                        v-model="filter2" hide-details clearable />
                      </v-col>
                  </v-row>
                  <v-row no-gutters class="fill-height">
                      <v-col class="overflow-auto fill-height grey lighten-5">
                          <v-list dense class="py-0">
                              <v-list-item-group v-model="fiscalizacoesInclusasSelecionadas" multiple active-class="orange white--text">
                                  <template v-for="(fiscalizacao, index) in fiscalizacoesInclusasFiltradas">
                                      <v-list-item class="py-0" two-line style="min-height: 50px" >
                                          <template v-slot:default="{ active, toggle }">
                                              <v-list-item-content class="py-0">
                                                  <v-list-item-title class="font-weight-light">{{fiscalizacao.financiamento.mutuario}}</v-list-item-title>
                                                  <v-list-item-title class="font-weight-light caption">
                                                      Valor: {{fiscalizacao.financiamento.valor | formatNumber}}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                                      <!--{{formatDistanciaPercorrida(fiscalizacao.distanciaPercorrida)}}-->
                                                  </v-list-item-title>
                                              </v-list-item-content>
                                              <v-list-item-action class="ma-0">
                                                  <v-btn icon :to="{ name: 'fiscalizacao_view', params: { id: parseInt(fiscalizacao.id) }}" target="_blank">
                                                      <v-icon color="grey lighten-1">mdi-share</v-icon>
                                                  </v-btn>
                                              </v-list-item-action>
                                          </template>
                                      </v-list-item>
                                      <v-divider ></v-divider>
                                  </template>
                              </v-list-item-group>
                          </v-list>
                      </v-col>
                      <v-col class="shrink d-flex flex-column justify-center align-center"
                             style="min-width: 60px">
                          <v-btn color="primary" height="40px" x-small class="mb-1"
                                 :disabled="fiscalizacoesInclusasSelecionadas.length === 0"
                                 @click="colocaFiscalizacoesEmExclusas">
                              <v-icon>mdi-chevron-double-right</v-icon>
                          </v-btn>
                          <v-btn color="primary" height="40px" x-small class="mt-1"
                                 :disabled="fiscalizacoesExclusasSelecionadas.length === 0"
                                 @click="colocaFiscalizacoesEmInclusas">
                              <v-icon>mdi-chevron-double-left</v-icon>
                          </v-btn>
                      </v-col>
                      <v-col class="overflow-auto fill-height grey lighten-5" >
                          <v-list dense class="py-0">
                              <v-list-item-group v-model="fiscalizacoesExclusasSelecionadas"  multiple active-class="orange white--text">
                                  <template v-for="(fiscalizacao, index) in fiscalizacoesExclusasFiltradas">
                                      <v-list-item class="py-0" two-line style="min-height: 50px" >
                                          <template v-slot:default="{ active, toggle }">
                                              <v-list-item-content class="py-0">
                                                  <v-list-item-title class="font-weight-light">{{fiscalizacao.financiamento.mutuario}}</v-list-item-title>
                                                  <v-list-item-title class="font-weight-light caption">
                                                      Valor: {{fiscalizacao.financiamento.valor | formatNumber}}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                                      <!--{{formatDistanciaPercorrida(fiscalizacao.distanciaPercorrida)}}-->
                                                  </v-list-item-title>
                                              </v-list-item-content>
                                              <v-list-item-action class="ma-0">
                                                  <v-btn icon :to="{ name: 'fiscalizacao_view', params: { id: parseInt(fiscalizacao.id) }}" target="_blank">
                                                      <v-icon color="grey lighten-1">mdi-share</v-icon>
                                                  </v-btn>
                                              </v-list-item-action>
                                          </template>
                                      </v-list-item>
                                      <v-divider ></v-divider>
                                  </template>
                              </v-list-item-group>
                          </v-list>
                      </v-col>
                  </v-row>
              </template>
              <v-alert v-else type="warning" dense>Nenhuma fiscalização que possa ser removida</v-alert>
          </template>
      </v-card-text>
      <v-card-actions>
        <div class="pa-3" style="width: 100%">
          <v-btn text @click="closeDialog">Cancelar</v-btn>

          <div class="float-right">
            <v-btn color="primary" text depressed
                   :disabled="fiscalizacoesExclusas.length === 0"
                   @click="processa" >
              Processar
            </v-btn>
          </div>

        </div>
      </v-card-actions>
      <v-overlay absolute :value="loading" color="white">
        <v-progress-circular :size="100" :width="7" color="deep-orange" indeterminate></v-progress-circular>
      </v-overlay>
    </v-card>
  </v-dialog>
</template>

<script>
import gql from "graphql-tag";

export default {
  name: "RemoverFiscalizacoesDialog",
  data() {
      return {
          dialog: false,
          loading: false,
          loteId: null,
          filter1: null,
          filter2: null,
          fiscalizacoes: null,
          totalFiscalizacoes: null,
          fiscalizacoesInclusas: [],
          fiscalizacoesInclusasSelecionadas: [],
          fiscalizacoesExclusas: [],
          fiscalizacoesExclusasSelecionadas: [],
        }
  },
  computed:{
    fiscalizacoesInclusasFiltradas:{
      cache:false,
      get(){
        return this.fiscalizacoesInclusas.filter(fiscalizacao => {
          if(this.filter1) {
            if (fiscalizacao.financiamento.mutuario.indexOf(this.filter1.toUpperCase()) > -1) {
              return true;
            }
            return false;
          }else{
            return true;
          }
        })

      }
    },
    fiscalizacoesExclusasFiltradas: {
      cache:false,
      get() {
        return this.fiscalizacoesExclusas.filter(fiscalizacao => {
          if (this.filter2) {
            if (fiscalizacao.financiamento.mutuario.indexOf(this.filter2.toUpperCase()) > -1) {
              return true;
            }
            return false;
          } else {
            return true;
          }
        })

      }
    }
  },
  methods:{
    openDialog(loteId) {
      this.dialog = true;
      this.loteId = loteId;
      this.getFiscalizacoes(this.loteId)
    },
    closeDialog() {
      this.dialog = false;
      this.fiscalizacoes = [];
      this.fiscalizacoesInclusas = [];
      this.fiscalizacoesInclusasSelecionadas = [];
      this.fiscalizacoesExclusas = [];
      this.fiscalizacoesExclusasSelecionadas = [];
      this.filter1 = null;
      this.filter2 = null;
    },
    getFiscalizacoes(loteId){
      this.loading = true;

      this.$apollo.query({
        query: gql`query($loteId:Int!){
            fiscalizacoes:fiscalizacoesSemNotaFiscalPorLote(loteId:$loteId){
                id
                financiamento{
                    mutuario
                    valor
                }
            }
        }`,
        variables: {
          loteId
        },
      }).then((result) => {
        this.fiscalizacoes = result.data.fiscalizacoes;
        this.fiscalizacoesInclusas = this._.cloneDeep(this.fiscalizacoes)
        this.loading = false;
      });
    },
    processa(){
      this.loading = true;
      let fiscalizacoesId = this.fiscalizacoesExclusas.map(f => f.id);
      this.$apollo.mutate({
        mutation: gql`mutation($fiscalizacoesId: [Int]!) {
            removeFiscalizacoesDoLote(fiscalizacoes_id: $fiscalizacoesId)
        }`,
        variables:{
          fiscalizacoesId
        }
      }).then(()=>{
        this.$notify({text: 'Fiscalizações removidas do lote com sucesso!', type: 'success'});
        this.$emit('completed');
        this.closeDialog();
      }).finally(()=>{
        this.loading = false;
      })
    },
    colocaFiscalizacoesEmExclusas(){
      let fiscalizacoes = this._.pullAt(this.fiscalizacoesInclusasFiltradas, this.fiscalizacoesInclusasSelecionadas);
      let indicesReais = [];
      fiscalizacoes.forEach(fiscalizacao => {
        indicesReais.push(this.fiscalizacoesInclusas.findIndex(value => value.id === fiscalizacao.id))
      })
      let removidos = this._.pullAt(this.fiscalizacoesInclusas, indicesReais);
      this.fiscalizacoesExclusas = this.fiscalizacoesExclusas.concat(removidos);
      this.fiscalizacoesInclusasSelecionadas = [];
    },
    colocaFiscalizacoesEmInclusas(){
      let fiscalizacoes = this._.pullAt(this.fiscalizacoesExclusasFiltradas, this.fiscalizacoesExclusasSelecionadas);
      let indicesReais = [];
      fiscalizacoes.forEach(fiscalizacao => {
        indicesReais.push(this.fiscalizacoesExclusas.findIndex(value => value.id === fiscalizacao.id))
      })
      let removidos = this._.pullAt(this.fiscalizacoesExclusas, indicesReais);
      this.fiscalizacoesInclusas = this.fiscalizacoesInclusas.concat(removidos);
      this.fiscalizacoesExclusasSelecionadas = [];
    }
  }
}
</script>

<style scoped>

</style>